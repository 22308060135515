import { LazyMotion } from 'framer-motion';
import dynamic from 'next/dynamic';

import { ApiSettings } from '@hultafors/shared/types';

import { GlobalFields } from '@hultafors/hultaforsgroup/types';

import { GlobalProvider } from '../global-context/global-context';

const MarketPickerProvider = dynamic(() =>
  import('@hultafors/shared/context').then(
    (module) => module.MarketPickerProvider,
  ),
);

const SWRProvider = dynamic(() =>
  import('@hultafors/shared/context').then((module) => module.SWRProvider),
);

const lazyMotionFeatures = () =>
  import('./lazy-motion-features').then((res) => res.default);
interface AppProviderProps {
  children?: React.ReactNode;
  value: GlobalFields & {
    settings: ApiSettings;
  };
}

export const AppProvider: React.FC<AppProviderProps> = ({
  children,
  value: { settings, ...value },
}) => {
  const swrConfig = {
    revalidateIfStale: false,
    revalidateOnMount: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  };
  const active = [settings?.hostname, settings?.language?.urlPrefix]
    .filter(Boolean)
    .join('/');
  return (
    <SWRProvider config={swrConfig}>
      <LazyMotion features={lazyMotionFeatures}>
        <GlobalProvider value={value} settings={settings}>
          <MarketPickerProvider
            label={value?.global?.changeLanguage ?? ''}
            markets={value?.global?.languages || []}
            active={active}
          >
            {children}
          </MarketPickerProvider>
        </GlobalProvider>
      </LazyMotion>
    </SWRProvider>
  );
};
